<template>
  <section class="help__wrapper">
    <div class="help">
      <div class="help__content">
        <span class="help__content-title">
          Приветствуем вас в электронной очереди!
        </span>
        <div class="help__content-text">
          <p>
            С помощью этого терминала вы можете подтвердить свой приезд по
            таймслоту из приложения и не тратить время на звонки в
            тех.поддержку.
          </p>
          <p>
            Если время вашего таймслота уже наступило, просто введите номер
            вашего авто, подтвердите приезд и ожидайте дальнейших SMS
            инструкций.
          </p>
          <p>
            Если у вас нет таймслота из приложения для приоритетного проезда вы
            по прежнему можете обратиться в тех.поддержку по телефону
            <span>{{ techSupportPhone }}</span> и записаться в общую очередь на
            терминал.
          </p>
        </div>
      </div>

      <div class="help__codes">
        <div class="help__codes-buyTimeslots">
          <img src="@/assets/icons/buyTimeslots.svg" alt="date" />
          <span>Сайт для бронирования таймслотов для iOS</span>
        </div>
        <div class="help__codes-timeslotsApp">
          <img src="@/assets/icons/timeslotsApp.svg" alt="date" />
          <span>
            Скачать приложение для бронирования таймслотов на Android
          </span>
        </div>
      </div>
    </div>

    <MainButton
      class="button"
      title="Назад"
      type="primary"
      size="big"
      width="full"
      @button-clicked="$emit('close')"
    />
  </section>
</template>

<script>
import { techSupportPhone } from '@/constants/mainData'
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton.vue'
export default {
  name: 'HelpModal',
  components: { MainButton },
  data() {
    return {
      techSupportPhone,
    }
  },
}
</script>

<style lang="sass" scoped>

.help__wrapper
  position: absolute
  height: calc(100vh - 70px)
  top: 70px
  left: 0
  right: 0
  bottom: 0
  background: $color-bgd-gray
  z-index: 10
  padding: 80px 110px 30px 70px
  display: flex
  flex-direction: column
  .button
    margin: 0 auto

.help
  display: flex
  column-gap: 60px
  justify-content: space-between
  margin-bottom: 50px
  &__content
    max-width: 520px
    &-title
      font-family: $fontPrimary
      font-style: normal
      font-weight: 500
      font-size: 40px
      margin-bottom: 30px
    &-text
      margin-top: 24px
      p
        text-transform: none
        font-size: 18px
        font-weight: 400
        line-height: 24px
        font-family: $fontSecondary
        margin-bottom: 24px
        span
          color: $special
          font-family: $fontSecondary
  &__codes
    display: flex
    flex-direction: column
    justify-content: space-between
    &-buyTimeslots
      display: flex
      align-items: center
      column-gap: 26px
      img
      span
        font-family: $fontSecondary
        max-width: 210px
        font-weight: 400
        font-size: 18px
    &-timeslotsApp
      display: flex
      align-items: center
      column-gap: 26px
      font-family: $fontSecondary
      img
      span
        max-width: 210px
        font-weight: 400
        font-size: 18px
        font-family: $fontSecondary
</style>
