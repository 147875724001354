var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "help__wrapper" },
    [
      _c("div", { staticClass: "help" }, [
        _c("div", { staticClass: "help__content" }, [
          _c("span", { staticClass: "help__content-title" }, [
            _vm._v(" Приветствуем вас в электронной очереди! "),
          ]),
          _c("div", { staticClass: "help__content-text" }, [
            _c("p", [
              _vm._v(
                " С помощью этого терминала вы можете подтвердить свой приезд по таймслоту из приложения и не тратить время на звонки в тех.поддержку. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Если время вашего таймслота уже наступило, просто введите номер вашего авто, подтвердите приезд и ожидайте дальнейших SMS инструкций. "
              ),
            ]),
            _c("p", [
              _vm._v(
                " Если у вас нет таймслота из приложения для приоритетного проезда вы по прежнему можете обратиться в тех.поддержку по телефону "
              ),
              _c("span", [_vm._v(_vm._s(_vm.techSupportPhone))]),
              _vm._v(" и записаться в общую очередь на терминал. "),
            ]),
          ]),
        ]),
        _vm._m(0),
      ]),
      _c("MainButton", {
        staticClass: "button",
        attrs: { title: "Назад", type: "primary", size: "big", width: "full" },
        on: {
          "button-clicked": function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "help__codes" }, [
      _c("div", { staticClass: "help__codes-buyTimeslots" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/icons/buyTimeslots.svg"),
            alt: "date",
          },
        }),
        _c("span", [_vm._v("Сайт для бронирования таймслотов для iOS")]),
      ]),
      _c("div", { staticClass: "help__codes-timeslotsApp" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/icons/timeslotsApp.svg"),
            alt: "date",
          },
        }),
        _c("span", [
          _vm._v(" Скачать приложение для бронирования таймслотов на Android "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }